<template>
  <div class="article-ranking-container">
    <el-card class="header">
      <div class="dynamic-box">
        <span class="title">{{ $t('msg.track.e2eTrackTitle') }}</span>
        <el-date-picker v-model="faValue" placeholder="Pick a Final Arrival Date"
                        value-format="YYYY-MM-DD" @change="infoByFA()"></el-date-picker>
        <span class="title">Or Pick a Date Range</span>
        <el-date-picker v-model="dateRange" type="daterange" range-separator="To" start-placeholder="Start date"
          end-placeholder="End date" value-format="YYYY-MM-DD" @change="infoByDateRange()"/>
      </div>
    </el-card>
    <el-card>
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('msg.track.containerInfo')" name="ContainerInfo">
          <el-table ref="tableRef" :data="tableData" border @filter-change="filterChange" @sort-change="sortChange">
            <el-table-column :label="$t('msg.excel.show')">
              <template #default="{row}">
                <el-button type="primary" size="small" @click="onShowClick(row)">{{ $t('msg.excel.show') }}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="trackingNumber" :label="$t('msg.track.trackingNumber')" sortable="custom"></el-table-column>
            <el-table-column prop="client" :label="$t('msg.track.client')" sortable="custom"></el-table-column>
            <el-table-column prop="mbl" :label="$t('msg.track.mbl')" sortable="custom"></el-table-column>
            <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')" sortable="custom"></el-table-column>
            <el-table-column prop="pol" :label="$t('msg.rate.pol')" sortable="custom"></el-table-column>
            <el-table-column prop="pod" :label="$t('msg.track.pod')" :filters="podList" column-key="pods" sortable="custom"></el-table-column>
            <el-table-column prop="podRegion" :label="$t('msg.track.podRegion')" sortable="custom"></el-table-column>
            <el-table-column prop="ctnrSize" :label="$t('msg.track.ctnrSize')" sortable="custom"></el-table-column>
            <el-table-column prop="ctnrCbm" :label="$t('msg.track.ctnrCbm')" sortable="custom"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="$t('msg.track.terminalInfo')" name="TerminalInfo">
          <el-table ref="tableRef" :data="tableData" border @sort-change="sortChange">
            <el-table-column prop="trackingNumber" :label="$t('msg.track.trackingNumber')" sortable="custom"></el-table-column>
            <el-table-column prop="ctnrKg" :label="$t('msg.track.ctnrKg')" sortable="custom"></el-table-column>
            <el-table-column prop="cartons" :label="$t('msg.track.cartons')" sortable="custom"></el-table-column>
            <el-table-column prop="swStatus" :label="$t('msg.track.swStatus')" sortable="custom"></el-table-column>
            <el-table-column prop="drayType" :label="$t('msg.track.drayType')" sortable="custom"></el-table-column>
            <el-table-column prop="transloadType" :label="$t('msg.track.transloadType')" sortable="custom"></el-table-column>
            <el-table-column prop="sslCarrier" :label="$t('msg.track.sslCarrier')" sortable="custom"></el-table-column>
            <el-table-column prop="vessel" :label="$t('msg.track.vessel')" sortable="custom"></el-table-column>
            <el-table-column prop="voyage" :label="$t('msg.track.voyage')" sortable="custom"></el-table-column>
            <el-table-column prop="firmsCode" :label="$t('msg.track.firmsCode')" sortable="custom"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="$t('msg.track.shipmentInfo')" name="ShipmentInfo">
          <el-table ref="tableRef" :data="tableData" border @sort-change="sortChange">
            <el-table-column prop="trackingNumber" :label="$t('msg.track.trackingNumber')" sortable="custom"></el-table-column>
            <el-table-column prop="dCBPStatus" :label="$t('msg.track.dCBPStatus')" sortable="custom"></el-table-column>
            <el-table-column prop="dFreightStatus" :label="$t('msg.track.dFreightStatus')" sortable="custom"></el-table-column>
            <el-table-column prop="dTerminalPickupStatus" :label="$t('msg.track.dTerminalPickupStatus')" sortable="custom"></el-table-column>
            <el-table-column prop="swDeparture" :label="$t('msg.track.swDeparture')" sortable="custom"></el-table-column>
            <el-table-column prop="swDischarge" :label="$t('msg.track.swDischarge')" sortable="custom"></el-table-column>
            <el-table-column prop="swFinalArrival" :label="$t('msg.track.swFinalArrival')" sortable="custom"></el-table-column>
            <el-table-column prop="swOG" :label="$t('msg.track.swOG')" sortable="custom"></el-table-column>
            <el-table-column prop="swER" :label="$t('msg.track.swER')" sortable="custom"></el-table-column>
            <el-table-column prop="fullGateIn" :label="$t('msg.track.fullGateIn')" sortable="custom"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="$t('msg.track.drayServiceInfo')" name="DrayServiceInfo">
          <el-table ref="tableRef" :data="tableData" border @sort-change="sortChange">
            <el-table-column prop="trackingNumber" :label="$t('msg.track.trackingNumber')" sortable="custom"></el-table-column>
            <el-table-column prop="ddray" :label="$t('msg.track.ddray')" sortable="custom"></el-table-column>
            <el-table-column prop="destSite" :label="$t('msg.track.destSite')" sortable="custom"></el-table-column>
            <el-table-column prop="etd" :label="$t('msg.track.etd')" sortable="custom"></el-table-column>
            <el-table-column prop="atd" :label="$t('msg.track.atd')" sortable="custom"></el-table-column>
            <el-table-column prop="eta" :label="$t('msg.track.eta')" sortable="custom"></el-table-column>
            <el-table-column prop="ata" :label="$t('msg.track.ata')" sortable="custom"></el-table-column>
            <el-table-column prop="discharge" :label="$t('msg.track.discharge')" sortable="custom"></el-table-column>
            <el-table-column prop="onRail" :label="$t('msg.track.onRail')" sortable="custom"></el-table-column>
            <el-table-column prop="offRail" :label="$t('msg.track.offRail')" sortable="custom"></el-table-column>
            <el-table-column prop="termLFD" :label="$t('msg.track.termLFD')" sortable="custom"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="$t('msg.track.emptyReturnInfo')" name="EmptyReturnInfo">
          <el-table ref="tableRef" :data="tableData" border @sort-change="sortChange">
            <el-table-column prop="trackingNumber" :label="$t('msg.track.trackingNumber')" sortable="custom"></el-table-column>
            <el-table-column prop="fullGateOut" :label="$t('msg.track.fullGateOut')" sortable="custom"></el-table-column>
            <el-table-column prop="dWHCrdd" :label="$t('msg.track.dWHCrdd')" sortable="custom"></el-table-column>
            <el-table-column prop="dWHArrival" :label="$t('msg.track.dWHArrival')" sortable="custom"></el-table-column>
            <el-table-column prop="dWHEmptyNotify" :label="$t('msg.track.dWHEmptyNotify')" sortable="custom"></el-table-column>
            <el-table-column prop="emptyReturnAppt" :label="$t('msg.track.emptyReturnAppt')" sortable="custom"></el-table-column>
            <el-table-column prop="emptyReturn" :label="$t('msg.track.emptyReturn')" sortable="custom"></el-table-column>
            <el-table-column prop="targetOG" :label="$t('msg.track.targetOG')" sortable="custom"></el-table-column>
            <el-table-column prop="targetER" :label="$t('msg.track.targetER')" sortable="custom"></el-table-column>
            <el-table-column prop="targetComplete" :label="$t('msg.track.targetComplete')" sortable="custom"></el-table-column>
            <el-table-column prop="smsId" :label="$t('msg.track.smsId')" sortable="custom"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[5, 10, 20,50, 100, 200]" :page-size="size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script setup>
import { ref, onActivated, computed, onMounted } from 'vue'
import { getE2ETrackByFA, getTrackByDateRange, getPodCtnr, getE2ETrackList, getTrackByPods } from '@/api/track'
import { watchSwitchLang } from '@/utils/i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
// 数据相关
const tableData = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)

const faValue = ref()
const dateRange = ref([])
const podCtnrData = ref([])
const podList = ref([])
const activeName = ref('ContainerInfo')
const store = useStore()

const dateFlag = computed(() => {
  return store.state.e2etrack.dateFlag
})
const sortCause = computed(() => {
  return store.state.e2etrack.sortClause
})
const filterChange = async (filterObj) => {
  store.commit('e2etrack/setSelectedPods', filterObj.pods)
  const res = await getTrackByPods({
    page: page.value,
    size: size.value,
    list: filterObj.pods,
    orderByClause: sortCause.value
  })
  tableData.value = res.list
  total.value = res.total
  store.commit('e2etrack/setDateFlag', 'podsChange')
}
const sortChange = async (column) => {
  let sort = (column.order === 'descending') ? ' DESC' : ' ASC'
  sort = column.prop + sort
  store.commit('e2etrack/setSortClause', sort)
  handleChange()
}

const getListData = async () => {
  const result = await getE2ETrackList({
    page: page.value,
    size: size.value,
    orderByClause: sortCause.value
  })
  tableData.value = result.list
  total.value = result.total
}

const getList = async () => {
  podCtnrData.value = await getPodCtnr()
  getPodList()
}
onMounted(() => {
  getList()
})

onActivated(getListData)

const handleSizeChange = currentSize => {
  size.value = currentSize
  handleChange()
}
const handleCurrentChange = currentPage => {
  page.value = currentPage
  handleChange()
}
const handleChange = () => {
  if (dateFlag.value === 'singleDate') {
    infoByFA()
  } else if (dateFlag.value === 'dateRange') {
    infoByDateRange()
  } else if (dateFlag.value === 'podsChange') {
    getSelectedPods()
  } else {
    getListData()
  }
}
const infoByFA = async () => {
  const res = await getE2ETrackByFA({
    page: page.value,
    size: size.value,
    faDate: faValue.value
  })
  tableData.value = res.list
  total.value = res.total
  store.commit('e2etrack/setDateFlag', 'singleDate')
}
const infoByDateRange = async () => {
  const res = await getTrackByDateRange({
    page: page.value,
    size: size.value,
    startDate: dateRange.value[0],
    endDate: dateRange.value[1],
    orderByClause: sortCause.value
  })
  tableData.value = res.list
  total.value = res.total
  store.commit('e2etrack/setDateFlag', 'dateRange')
}
const getSelectedPods = async () => {
  const res = await getTrackByPods({
    page: page.value,
    size: size.value,
    list: store.state.e2etrack.selectedPods,
    orderByClause: sortCause.value
  })
  tableData.value = res.list
  total.value = res.total
}
const router = useRouter()
const onShowClick = row => {
  const refId = row.smsId
  router.push(`/e2eTrack/info/${refId}`)
}
// eslint-disable-next-line no-unused-vars
const i18n = useI18n()
watchSwitchLang(handleChange)
function getPodList() {
  for (let i = 0; i < podCtnrData.value.length; i++) {
    const textInList = podCtnrData.value[i].name
    podList.value.push({ text: textInList, value: textInList })
  }
}
</script>

<style lang="scss" scoped>
.article-ranking-container {
  .header {
    margin-bottom: 20px;
    .dynamic-box {
      display: flex;
      align-items: center;
      .title {
        margin-right: 20px;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  ::v-deep .el-table__row {
    cursor: pointer;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}

::v-deep .sortable-ghost {
  opacity: 0.6;
  color: #fff !important;
  background: #304156 !important;
}
</style>
